<template>
  <div>
    <CCard>
      <ValidationObserver v-slot="{ handleSubmit }">
        <CForm
          @submit.prevent="
            handleSubmit(isUserUpdate ? showModal : onAddUserFormSubmit)
          "
          autocomplete="off"
        >
          <CCardHeader class="grey-header">
            <CRow class="flex-flow-column">
              <CCol>
                <strong>{{
                  isUserUpdate
                    ? $t("addUser.updateTitle")
                    : $t("addUser.addTitle")
                }}</strong>

                <!-- <span class="desc" v-show="isUserUpdate">
                                {{$t('addUser.updateDesc')}}

                </span>-->
              </CCol>

              <p class="desc" v-if="isUserUpdate">
                {{ $t("addUser.updateDesc") }}
              </p>
              <p class="desc" v-else>{{ $t("addUser.addDesc") }}</p>
            </CRow>
          </CCardHeader>
          <CCardBody class="dek-w-50">
            <CRow v-if="!selectedUserObj && adminSignIn">
              <CCol>
                <CRow class="mb-20px">
                  <CCol
                    v-for="(type, index) in lsUserTypes"
                    :key="index + 'ls'"
                    class="d-flex"
                  >
                    <cRadioButton
                      class="radio-inline"
                      v-model="userType"
                      :name="type.name"
                      :value="type.code"
                      :label="type.name"
                      @input="user.roles = null"
                    >
                    </cRadioButton>

                    <span
                      v-if="type.name == 'System'"
                      class="d-flex align-item-center"
                    >
                      <cHelpTip :content="$t('helpTip.addUserSys')"></cHelpTip>
                    </span>

                    <span
                      v-if="type.name == 'Agent'"
                      class="d-flex align-item-center"
                    >
                      <cHelpTip :content="$t('helpTip.addUserAgt')"></cHelpTip>
                    </span>
                  </CCol>
                </CRow>

                <div class="no-50per-dev-select inner-label-none mb-15px">
                  <cAgent
                    v-if="userType.code == 'agt'"
                    agentType="null"
                    v-model="agent"
                    :isMultiple="false"
                    :rules="'required'"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow v-else>
              <strong class="col mb-10px" v-if="selectedUserObj">
                {{ selectedUserObj.agencyName }}
              </strong>
              <!--           <br>-->
            </CRow>
            <!--             <br>-->

            <CRow class="col-child-mb-15px">
              <CCol md="6" sm="12">
                <cTextInputComponent
                  v-model="user.firstname"
                  :placeholder="$t('addUser.fName')"
                  :label="$t('addUser.fName')"
                  :name="$t('addUser.fName')"
                  rules="required|noInitialSpace|max:30|onlyAlphabets"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
              </CCol>
              <CCol md="6" sm="12">
                <cTextInputComponent
                  v-model="user.lastname"
                  :placeholder="$t('addUser.lName')"
                  :name="$t('addUser.lName')"
                  :label="$t('addUser.lName')"
                  rules="noInitialSpace|max:30|onlyAlphabets"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
              </CCol>
              <CCol md="6" sm="12">
                <cTextInputComponent
                  v-model="user.emailid"
                  :placeholder="$t('addUser.emailId')"
                  :label="$t('addUser.emailId')"
                  :name="$t('addUser.emailId')"
                  rules="required|email"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
              </CCol>

              <CCol md="6" sm="12">
                <cTextInputComponent
                  type="password"
                  v-model="user.password"
                  :placeholder="$t('addUser.pwd')"
                  :label="$t('addUser.pwd')"
                  :name="$t('addUser.pwd')"
                  :rules="getPwdRules()"
                  :isDisable="isUserUpdate"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
              </CCol>

              <CCol v-if="userType && userType.code == 'agt'" md="6" sm="12">
                <cDropDown
                  key="agt"
                  :isB2BRole="true"
                  v-on:input="onRoleChange"
                  :selValue="user.roles"
                  :ctrlName="'roleName'"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isSearchable="true"
                  :url="rolesServiceURL"
                  rules="required"
                  :label="$t('addUser.role')"
                />
              </CCol>

              <CCol v-else md="6" sm="12">
                <cDropDown
                  key="sys"
                  v-on:input="onRoleChange"
                  :isSystemRole="true"
                  :selValue="user.roles"
                  :ctrlName="'roleName'"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isSearchable="true"
                  :url="rolesServiceURL"
                  rules="required"
                  :label="$t('addUser.role')"
                />
              </CCol>

              <CAlert v-if="alertValue" show color="danger">
                <div
                  v-if="alertValue && alertValue.hasOwnProperty('valueList')"
                >
                  <p
                    v-for="(value, index) in alertValue.valueList"
                    :key="index"
                  >
                    {{ value }}
                  </p>
                </div>
                <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
                  <span>{{ $t("cAlert.errorCode") + " : " }}</span
                  ><span
                    v-for="(code, index) in alertValue.codeList"
                    :key="index"
                  >
                    {{ code + " " }}
                  </span>
                </div>
                <div v-if="alertValue && alertValue.constructor == String">
                  <p>
                    {{ alertValue }}
                  </p>
                </div>
              </CAlert>
            </CRow>
            <!--            <br>-->
          </CCardBody>

          <CCardFooter class="d-flex justify-content-end">
            <div class="main-cta-btn">
              <CButton
                @click="navigateToAllUsers()"
                color="danger"
                class="action-c-btn"
              >
                <img src="@imgPath/img/svg/icons/close.svg" />
                {{ $t("addUser.cancel") }}
              </CButton>
              <CButton
                col="12"
                type="submit"
                color="success removewrap"
                class="action-c-btn"
              >
                <img src="@imgPath/img/svg/icons/correct.svg" />
                {{
                  isUserUpdate ? $t("addUser.update") : $t("addUser.addUser")
                }}
              </CButton>
            </div>
          </CCardFooter>
        </CForm>
      </ValidationObserver>
    </CCard>

    <!-- <cAlertModal
      :buttonList="showAlertModal?getModalBtnLs():null"
      :status="1"
      :show="showAlertModal"
      :desc="(user.firstname+' '+user.lastname +' '+( isUserUpdate? $t('addUser.updateSuccess'):$t('addUser.addSuccess')))"
    ></cAlertModal> -->
    <cAlertModal
      v-if="showAlertModal"
      :buttonList="buttonList"
      :status="modalStatus"
      :show="showAlertModal"
      :desc="confirmText"
    ></cAlertModal>
  </div>
</template>

<script>
import BaseConfiguration from "../../config/base";

import { goBack, updateDataEdited } from "@/router/index";
import { ValidationObserver } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import cTextInputComponent from "@/components/form/cTextInputComponent";
import cRadioButton from "./../../components/form/cRadioButton";
import cDropDown from "@/components/form/cDropDown";
import cAlertModal from "./../../components/form/cAlertModal.vue";
import {
  COMPONENT_NAME,
  USERS_TYPE,
  ALERT_MODAL_STATUS,
} from "./../../util/constants";
import apiCreateUser from "./../../services/apiCreateUser";
import apiUpdateUser from "./../../services/apiUpdateUser";
import Logger from "./../../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
} from "./../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
} from "../../util/util";
import cHelpTip from "./../../components/cHelpTip";
// import nb from "../../translations/validation/nb.json";
//     import en from "../../translations/validation/en.json";
//  localize({
//         en: {
//             messages: en.messages
//         },
//         nb: {
//             messages: nb.messages
//         }
//     });
export default {
  name: COMPONENT_NAME.ADD_USER,
  components: {
    ValidationObserver,
    cTextInputComponent,
    cAlertModal,
    cDropDown,
    cRadioButton,
    cHelpTip,
  },

  data: () => {
    return {
      rolesServiceURL: SERVICE_URL.GET_USER_ROLES,
      lsUserTypes: [],

      userType: {
        name: null,
        code: null,
      },

      agent: null,

      user: {
        firstname: null,
        lastname: null,
        emailid: null,
        password: null,
        roles: {
          roleName: null,
        },
      },
      isUserUpdate: false,
      alertValue: null,
      showAlertModal: false,
      modalStatus: null,
      buttonList: [],
      confirmText: null,
    };
  },

  methods: {
    ...mapActions("sAddUser", {
      SET_CREATE_USER_RESPONSE: "SET_CREATE_USER_RESPONSE",
      SET_CREATE_USER_REQUEST: "SET_CREATE_USER_REQUEST",
      SET_UPDATE_USER_RESPONSE: "SET_UPDATE_USER_RESPONSE",
      SET_UPDATE_USER_REQUEST: "SET_UPDATE_USER_REQUEST",
      CLEAR_ADD_USER_STORE: "CLEAR_ADD_USER_STORE",
    }),
    ...mapActions("sManageUsers", {
      SET_ALL_USER_RESPONSE: "SET_ALL_USER_RESPONSE",
    }),
    onEdit() {
      try {
        if (BaseConfiguration.isDebug) console.log("onEdit");
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onEdit",
          err.toString(),
          "onEdit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    getModalBtnLs() {
      return [
        {
          name: this.$t("addUser.ok"),
          func: this.navigateToAllUsers,
          color: "success",
        },
      ];
    },
    onRoleChange(pSelectedRole) {
      if (pSelectedRole) {
        this.user.roles = pSelectedRole;
      }
      updateDataEdited(true);
    },

    showAlert(value) {
      this.alertValue = value;
    },

    toggleAlertModal() {
      this.showAlertModal = !this.showAlertModal;
    },

    showModal(pStatus) {
      try {
        // let retValue;
        this.showAlertModal = true;
        if (pStatus) {
          this.modalStatus = pStatus;
        } else {
          this.modalStatus = this.isUserUpdate
            ? ALERT_MODAL_STATUS.INFO
            : ALERT_MODAL_STATUS.SUCCESS;
        }
        let desc = null;
        if (this.modalStatus == ALERT_MODAL_STATUS.INFO) {
          this.buttonList = [
            {
              name: this.$t("addUser.no"),
              func: this.toggleAlertModal,
              color: "danger",
            },
            {
              name: this.$t("addUser.yes"),
              func: this.onAddUserFormSubmit,
              color: "success",
            },
          ];
          desc = this.$t("addUser.confirmUpdate", {
            fname: this.user.firstname,
            lname: this.user.lastname,
          });
        } else if (this.modalStatus == ALERT_MODAL_STATUS.SUCCESS) {
          this.buttonList = [
            {
              name: this.$t("addUser.ok"),
              func: this.navigateToAllUsers,
              color: "danger",
            },
          ];
          desc = this.isUserUpdate
            ? this.$t("addUser.updateSuccess", {
                fname: this.user.firstname,
                lname: this.user.lastname,
              })
            : this.$t("addUser.addSuccess", {
                fname: this.user.firstname,
                lname: this.user.lastname,
              });
        }
        this.confirmText = desc;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in showModal",
          err.toString(),
          "showModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

    async onAddUserFormSubmit() {
      try {
        if (this.isUserUpdate) {
          let updateUserStatus = await this.getUpdateUserStatus(this.user);
          if (updateUserStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS);
        } else {
          let createUserStatus = await this.getCreateUserStatus(this.user);
          if (createUserStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS);
        }
        updateDataEdited(false);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  on Add User Form Submit",
          err.toString(),
          "onAddUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async getCreateUserStatus(pUser) {
      let returnValue;

      try {
        this.showAlertModal = false;

        let createUserRequest = apiCreateUser.buildRequest(pUser);

        if (createUserRequest != null) {
          this.SET_CREATE_USER_REQUEST(createUserRequest);

          let createUserResponse = await apiCreateUser.getResponse(
            createUserRequest
          );
          if (VALIDATE_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
            this.SET_CREATE_USER_RESPONSE(returnValue);
            this.clearAllUserRes();
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Create User Status",
          err.toString(),
          "getCreateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    clearAllUserRes() {
      this.SET_ALL_USER_RESPONSE(null);
    },
    async getUpdateUserStatus(pUser) {
      let returnValue;

      try {
        this.showAlertModal = false;
        let updateUserRequest = apiUpdateUser.buildRequest(pUser);
        if (updateUserRequest) {
          this.SET_UPDATE_USER_REQUEST(updateUserRequest);
          let updateUserResponse = await apiUpdateUser.getResponse(
            updateUserRequest
          );

          if (VALIDATE_SUCCESS_RES(updateUserResponse)) {
            //succ
            returnValue = updateUserResponse.data;
            this.SET_UPDATE_USER_RESPONSE(returnValue);
            this.showAlert(null);

            this.clearAllUserRes();
          } else {
            //err

            let error = getErrorMessage(updateUserResponse);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Update User Status",
          err.toString(),
          "getUpdateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }

      return returnValue;
    },
    navigateToAllUsers() {
      try {
        goBack();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  navigate To All Users",
          err.toString(),
          "navigate To All Users"
        );
        Logger.getResponse(body);
      }
    },
    getPwdRules() {
      let returnValue;
      if (this.isUserUpdate) {
        returnValue = "minmax:8,20|number";
      } else {
        returnValue = "noSpaceAllowed|required|validPwd|minmax:8,20";
      }
      return returnValue;
    },
  },

  computed: {
    ...mapGetters("sAddUser", {
      selectedUserObj: "GET_SELECTED_USER",
    }),

    ...mapGetters("sSysLogin", { adminSignIn: "GET_ADMIN_SIGNIN_RES" }),
  },

  created() {
    try {
      if (this.selectedUserObj != null) {
        this.isUserUpdate = true;
        this.user = this.selectedUserObj;

        this.user.roles = {
          roleName: this.selectedUserObj.roles[0],
        };
      } else {
        this.isUserUpdate = false;
      }
      this.lsUserTypes = [
        {
          name: this.$t("addUser.system"),
          code: USERS_TYPE.SYSTEM,
        },
      ];

      if (
        (!this.selectedUserObj && this.adminSignIn)
      ) {
        this.userType.code = this.lsUserTypes[0].code;
      } else {
        this.userType.code = this.lsUserTypes[1].code;
      }
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.ADD_USER,
        "error in  Created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );

      Logger.getResponse(body);
    }
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.ADD_USER);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>

<style>
.input {
  border-radius: 30px;

  width: 300px;
  height: 30px;
}
</style>
